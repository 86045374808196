@use '../base' as *;

.main-menu {
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
        font-size: 36px;
        margin-top: 50px;
        padding: 0;
        font-family: PixelTitle, sans-serif;
        color: $color-elevation-two-primary-foreground;
        text-transform: uppercase;
        font-weight: 800;

        @include stroke(5, $color-elevation-one-border);

        @media (min-width: 600px) {
            font-size: 48px;
        }
    }

    .buttons {
        flex: 1 1 100%;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }

        button + button {
            margin-top: $spacing-base;
        }
    }
}
