@use '../base' as *;

.lobby {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    font-size: 24px;

    button {
        width: 300px;
    }

    .title {
        font-size: 22px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;

        .game-code {
            letter-spacing: 5px;
            color: $color-error-foreground;
        }

        @include stroke(2, $color-elevation-one-primary-foreground);

        @media (min-width: 600px) {
            font-size: 32px;
        }
    }

    .subtitle {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .player {
        margin: calc($spacing-base / 2) 0 $spacing-base 0;
    }

    .config {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        button {
            width: 60px;
            font-size: 24px;
            margin-top: 0;
        }

        .counter {
            font-size: 30px;
            font-weight: bold;
        }
    }

    .fields {
        flex: 1 1 100%;
        align-content: center;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 300px;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }

        .field + .field {
            margin-top: $spacing-base;
        }
    }

    .wait-icon {
        margin-top: 50px;
        animation: rotate3d 2.5s linear infinite;
        width: 36px !important;
        align-self: center;

        @keyframes rotate3d {
            from {
                transform: rotate3d(0, 1, 0, 0deg);
            }

            to {
                transform: rotate3d(0, 1, 0, 360deg);
            }
        }
    }

    .error-message {
        color: $color-error-foreground;
        font-weight: 800;
    }
}
