@use '../base' as *;

.word-input {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;

    .title {
        font-size: 18px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-one-primary-foreground;
        font-weight: 800;

        @media (min-width: 600px) {
            font-size: 24px;
        }
    }

    .label {
        font-weight: 800;
        text-align: left;

        &:not(:first-child) {
            margin-top: calc($spacing-base * 2);
        }
    }

    .error-message {
        color: $color-error-foreground;
        font-weight: 800;
    }

    .fields {
        flex: 1 1 100%;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }

        .field + .field {
            margin-top: $spacing-base;
        }
    }
}
