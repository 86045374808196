@use '../base' as *;

.new-game {
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
        font-size: 36px;
        margin: 50px 0;
        padding: 0;
        font-family: PixelTitle, sans-serif;
        color: $color-elevation-two-primary-foreground;
        text-transform: uppercase;
        font-weight: 800;

        @include stroke(5, $color-elevation-one-border);

        @media (min-width: 600px) {
            font-size: 48px;
        }
    }

    .subtitle {
        font-size: 24px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;

        @include stroke(2, $color-elevation-one-primary-foreground);

        @media (min-width: 600px) {
            font-size: 36px;
        }
    }

    .loading {
        align-self: center;
    }

    .error-message {
        font-size: 24px;
        color: $color-error-foreground;
        font-weight: 800;
        margin: 10px 0;
    }

    .fields {
        flex: 1 1 100%;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }

        .label {
            font-size: 20px;
            font-weight: 800;
            text-align: left;
            margin-bottom: calc($spacing-base);

            &:not(:first-child) {
                margin-top: calc($spacing-base * 2);
            }
        }

        .field + .field {
            margin-top: $spacing-base;
        }
    }
}
