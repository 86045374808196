@use '../base' as *;

.finished {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;

    .title {
        font-size: 36px;
        margin: 50px 0;
        padding: 0;
        font-family: PixelTitle, sans-serif;
        color: $color-elevation-two-primary-foreground;
        text-transform: uppercase;
        font-weight: 800;

        @include stroke(5, $color-elevation-one-border);

        @media (min-width: 600px) {
            font-size: 48px;
        }
    }

    .subtitle {
        font-size: 16px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-one-primary-foreground;
        font-weight: 800;

        @media (min-width: 600px) {
            font-size: 22px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .player {
        font-size: 16px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;

        @include stroke(2, $color-elevation-one-border);

        @media (min-width: 600px) {
            font-size: 22px;
        }

        &.first {
            color: $color-elevation-one-secondary-foreground;
        }

        .medal {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            width: 29px;
            height: 34px;
            margin-right: 15px;

            &.first {
                background-image: url('../../assets/first.png');
            }

            &.second {
                background-image: url('../../assets/second.png');
            }

            &.third {
                background-image: url('../../assets/third.png');
            }
        }
    }

    .icon {
        margin-top: 10px;
        animation: rotate3d 2.5s linear infinite;
        width: 91px !important;
        height: 101px !important;
        align-self: center;

        @keyframes rotate3d {
            from {
                transform: rotate3d(0, 1, 0, 0deg);
            }

            to {
                transform: rotate3d(0, 1, 0, 360deg);
            }
        }
    }

    .points {
        margin-left: 15px;
    }
}
