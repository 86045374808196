@use "../base" as *;

.loading-icon {
    animation: rotate 1.5s linear infinite; 
    width: 100px !important;
    height: 100px !important;

    @keyframes rotate {
        from {
            transform: rotate(360deg);
        }

        to {
            transform: rotate(0deg);
        }
    }
}
