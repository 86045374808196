@use '../base' as *;

.textarea {
    position: relative;
    margin-top: 20px;

    textarea {
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
        padding-left: 10px;
        letter-spacing: 1px;

        font-family: Pixel, sans-serif;
        font-size: 18px;

        background: $color-elevation-app-primary-background;
        border: 0;

        color: $color-elevation-two-primary-foreground;

        resize: none;
        min-height: 100px;
    }

    .textarea-border {
        width: 10px;
        height: 14px;
        position: absolute;

        &.top {
            width: auto;
            background-color: $color-elevation-two-secondary-background;
            top: -13px;
            left: 0px;
            right: 0px;
        }

        &.top-left {
            background-color: $color-elevation-two-secondary-background;
            top: -4px;
            left: -10px;
        }

        &.top-right {
            background-color: $color-elevation-two-secondary-background;
            top: -4px;
            right: -10px;
        }

        &.left {
            background-color: $color-elevation-app-primary-background;
            top: 10px;
            left: -10px;
            height: auto;
            bottom: 15px;
        }

        &.right {
            background-color: $color-elevation-app-primary-background;
            top: 10px;
            right: -10px;
            height: auto;
            bottom: 15px;
        }
    }
}
