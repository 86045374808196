@use 'base' as *;

html {
    box-sizing: border-box;
    margin: 0;
}

body {
    margin: 0;
    font-family: Pixel, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: $gradient-elevation-app-background;
    color: $color-elevation-one-primary-foreground;

    a {
        color: $color-elevation-one-primary-foreground;
        text-decoration: none;

        &:hover {
            color: $color-elevation-one-secondary-foreground;
        }
    }
}
