@use '../base' as *;

.meaning-vote {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;
    font-size: 18px;

    .subtitle {
        font-weight: 800;
        text-align: center;
    }

    .word {
        font-size: 22px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;

        @include stroke(2, $color-elevation-one-primary-foreground);

        @media (min-width: 600px) {
            font-size: 24px;
        }
    }

    .label {
        font-weight: 800;
        text-align: center;
        margin-bottom: $spacing-base;

        &:not(:first-child) {
            margin-top: calc($spacing-base * 2);
        }
    }

    .vote-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .button-wrapper {
        width: 60px;
        margin: $spacing-base + 14px;
    }

    .fields {
        flex: 1 1 100%;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }
    }
}
