@use '../base' as *;

.text-input {
    position: relative;
    margin-top: 20px;

    input {
        width: 100%;
        height: 59px;
        box-sizing: border-box;
        padding-left: 15px;

        font-family: Pixel, sans-serif;
        font-size: 28px;

        background: $color-elevation-app-primary-background;
        border: 0;

        color: $color-elevation-two-primary-foreground;
    }

    .input-border {
        width: 10px;
        height: 14px;
        position: absolute;

        &.top {
            width: auto;
            background-color: $color-elevation-two-secondary-background;
            top: -13px;
            left: 0px;
            right: 0px;
        }

        &.top-left {
            background-color: $color-elevation-two-secondary-background;
            top: -4px;
            left: -10px;
        }

        &.top-right {
            background-color: $color-elevation-two-secondary-background;
            top: -4px;
            right: -10px;
        }

        &.left {
            background-color: $color-elevation-app-primary-background;
            top: 10px;
            left: -10px;
            height: auto;
            bottom: 10px;
        }

        &.right {
            background-color: $color-elevation-app-primary-background;
            top: 10px;
            right: -10px;
            height: auto;
            bottom: 10px;
        }
    }
}
