@use '../base' as *;

.round-result {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 20px;

    button {
        margin-top: $spacing-base;
    }

    .word {
        font-size: 24px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;

        @include stroke(2, $color-elevation-one-primary-foreground);

        @media (min-width: 600px) {
            font-size: 32px;
        }
    }

    .answer-container {
        position: relative;
        padding: $spacing-base;
        margin: 15px;
        text-align: center;
        color: $color-elevation-two-primary-foreground;

        background: $color-elevation-one-primary-foreground;

        .answer-container-border {
            width: 10px;
            height: 14px;
            position: absolute;
            background-color: $color-elevation-app-primary-background;

            &.top {
                width: auto;
                background-color: $color-elevation-two-secondary-background;
                top: -13px;
                left: 0px;
                right: 0px;
            }

            &.top-left {
                background-color: $color-elevation-two-secondary-background;
                top: -4px;
                left: -10px;
            }

            &.top-right {
                background-color: $color-elevation-two-secondary-background;
                top: -4px;
                right: -10px;
            }

            &.left {
                background-color: $color-elevation-app-primary-background;
                top: 10px;
                left: -10px;
                height: auto;
                bottom: 10px;
            }

            &.right {
                background-color: $color-elevation-app-primary-background;
                top: 10px;
                right: -10px;
                height: auto;
                bottom: 10px;
            }
        }

        .answer-number {
            font-size: 36px;
            font-weight: bold;
        }

        .correct {
            position: absolute;
            top: 10px;
            right: 5px;
            background-image: url('../../assets/correct.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 32px;
            height: 35px;
        }

        .creative {
            position: absolute;
            top: 10px;
            left: 5px;
            background-image: url('../../assets/creative.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 32px;
            height: 35px;
        }
    }

    .subtitle {
        font-weight: bold;
        font-size: 20px;
        margin: 10px;
    }

    .title {
        font-size: 24px;
        margin: calc($spacing-base * 2) 0;
    }

    .player {
        margin: 8px 0 5px 0;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;

        .medal {
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
            width: 29px;
            height: 34px;
            margin-right: 8px;

            &.first {
                background-image: url('../../assets/first.png');
            }

            &.second {
                background-image: url('../../assets/second.png');
            }

            &.third {
                background-image: url('../../assets/third.png');
            }
        }
    }

    .player-vote {
        color: $color-elevation-two-border;
    }

    .points {
        color: $color-error-foreground;
        margin-left: 8px;
    }
}
