@use '../base' as *;

.disclaimer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $spacing-base;
    color: $color-elevation-one-primary-foreground;
    font-size: 24px;

    a {
        margin-bottom: $spacing-base;
    }

    @media (min-width: 600px) {
        flex-direction: row;

        a {
            margin-bottom: 0;
        }
    }
}

.separator {
    display: none;

    @media (min-width: 600px) {
        display: inline-block;
    }
}
