@use '../base' as *;

.wait-status {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;

    .title {
        font-size: 16px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-one-primary-foreground;
        font-weight: 800;

        @media (min-width: 600px) {
            font-size: 22px;
        }
    }

    .message {
        text-align: center;
        font-size: 18px;

        &:not(:first-child) {
            margin-top: $spacing-base;
        }
    }

    .wait-icon {
        margin-top: 50px;
        animation: rotate3d 2.5s linear infinite;
        width: 36px !important;
        align-self: center;

        @keyframes rotate3d {
            from {
                transform: rotate3d(0, 1, 0, 0deg);
            }

            to {
                transform: rotate3d(0, 1, 0, 360deg);
            }
        }
    }
}
