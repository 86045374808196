@font-face { font-family: Pixel; src: url('../assets/pixel.ttf'); }
@font-face { font-family: PixelTitle; src: url('../assets/pixel2.ttf'); }

// BASIC
$spacing-base: 10px;
$color-error-foreground: rgb(255, 0, 41);
$color-disabled-foreground: rgb(94, 94, 94);
$color-disabled-background: rgb(187, 187, 187);

// ELEVATION APP
$color-elevation-app-primary-background: rgb(34, 61, 61);
$color-elevation-app-secondary-background: rgb(31, 56, 56);
$color-elevation-app-primary-foreground: rgb(7, 38, 79);

$gradient-elevation-app-background: linear-gradient(
    100deg,
    $color-elevation-app-primary-background 0%,
    $color-elevation-app-secondary-background 100%
);

// ELEVATION ONE
$color-elevation-one-primary-foreground: rgb(34, 61, 61);
$color-elevation-one-secondary-foreground: rgb(255, 169, 41);
$color-elevation-one-border: rgb(7, 38, 79);
$color-elevation-one-primary-background: rgb(228, 247, 247);
$color-elevation-one-secondary-background: rgb(228, 247, 247);

$gradient-elevation-one-background: linear-gradient(
    312deg,
    $color-elevation-one-primary-background 0%,
    $color-elevation-one-secondary-background 100%
);

// ELEVATION TWO
$color-elevation-two-primary-foreground: rgb(228, 247, 247);
$color-elevation-two-primary-background: rgb(134, 204, 250);
$color-elevation-two-secondary-background: rgb(151, 207, 207);
$color-elevation-two-border: rgb(255, 169, 41);

$gradient-elevation-two-background: linear-gradient(
    100deg,
    $color-elevation-two-primary-background 0%,
    $color-elevation-two-secondary-background 100%
);

// BORDER SIZES
$border-small: 2px;
$border-base: 4px;
$border-medium: 9px;
$border-large: 15px;

@function stroke($stroke, $color) {
    $shadow: ();
    $from: $stroke * -1;
    @for $i from $from through $stroke {
        @for $j from $from through $stroke {
            $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
        }
    }
    @return $shadow;
}

@mixin stroke($stroke, $color) {
    text-shadow: stroke($stroke, $color);
}

@function top-shadow($depth) {
    $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
    $blur: nth(1.5 3 10 14 19, $depth) * 4px;
    $color: rgba(black, nth(0.12 0.16 0.19 0.25 0.3, $depth));

    @return 0 $primary-offset $blur $color;
}

@function bottom-shadow($depth) {
    $primary-offset: nth(1.5 3 6 10 15, $depth) * 1px;
    $blur: nth(1 3 3 5 6, $depth) * 4px;
    $color: rgba(black, nth(0.24 0.23 0.23 0.22 0.22, $depth));

    @return 0 $primary-offset $blur $color;
}

@mixin card($depth) {
    @if $depth < 1 {
        box-shadow: none;
    } @else if $depth > 5 {
        @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    } @else {
        box-shadow: bottom-shadow($depth), top-shadow($depth);
    }
}
