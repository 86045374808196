@use '../base' as *;

.game {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    .game-status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 16px;
    }
}
