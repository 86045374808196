@use 'base' as *;

.app-content {
    position: relative;
    width: 100vw;
    min-height: 100vh;

    margin: 0;
    padding: 20px;
    text-align: center;

    background: $color-elevation-one-primary-background;
    //border: $border-large solid $color-elevation-app-primary-background;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;

    @media (min-width: 600px) {
        overflow-x: unset;
        width: 25vw;
        min-width: 600px;
        min-height: 70vh;
        padding: 10px;

        margin: 0 auto;
        align-items: center;

        margin-top: calc(30vh / 2);

    }

    .border {
        background-image: url('../assets/main_bg.png');
        background-repeat: no-repeat;
        width: 35px;
        height: 35px;
        position: absolute;

        &.top-left {
            background-position: 0px 0px;
            top: -11px;
            left: -12px;
        }

        &.top-right {
            background-position: -93px 0px;
            top: -11px;
            right: -12px;
        }

        &.bottom-left {
            background-position: 0px -88px;
            bottom: -25px;
            left: -12px;
            height: 36px;
        }

        &.bottom-right {
            background-position: -93px -88px;
            bottom: -25px;
            right: -12px;
            height: 36px;
        }

        &.bottom {
            background-image: url('../assets/main_bg_bottom_border.png');
            bottom: -22px;
            right: 23px;
            left: 23px;
            height: 22px;
            background-repeat: repeat-x;
            width: auto;
        }

        &.top {
            background: $gradient-elevation-one-background;
            top: -11px;
            right: 22px;
            left: 22px;
            height: 12px;
            width: auto;
        }

        &.left {
            background-image: url('../assets/main_bg_area.png');
            top: 22px;
            bottom: 11px;
            left: -12px;
            background-repeat: repeat;
            width: 12px;
            height: auto;
        }

        &.right {
            background-image: url('../assets/main_bg_area.png');
            top: 22px;
            bottom: 11px;
            right: -12px;
            background-repeat: repeat;
            width: 12px;
            height: auto;
        }
    }
}
