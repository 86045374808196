@use '../base' as *;

.meaning-input {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 50px;

    .subtitle {
        font-weight: 800;
        text-align: center;
    }

    .word {
        font-size: 18px;
        margin: 15px 0;
        padding: 0;
        color: $color-elevation-two-primary-foreground;
        font-weight: 800;

        @include stroke(2, $color-elevation-one-primary-foreground);

        @media (min-width: 600px) {
            font-size: 24px;
        }
    }

    .label {
        font-weight: 800;
        text-align: left;

        &:not(:first-child) {
            margin-top: calc($spacing-base * 2);
        }
    }

    .error-message {
        color: $color-error-foreground;
        font-weight: 800;
    }

    .meaning-text {
        resize: none;
        min-width: 50vw;
        min-height: 70px;
        background: $color-elevation-app-primary-background;
        border: $border-small outset $color-elevation-one-border;

        font-size: 12px;
        color: $color-elevation-app-primary-foreground;
    }

    .fields {
        flex: 1 1 100%;
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (min-width: 600px) {
            flex: 1 1 50%;
        }

        .field + .field {
            margin-top: $spacing-base;
        }
    }
}
