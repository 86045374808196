@use '../base' as *;

.button {
    position: relative;
    width: 100%;
    height: 78px;
    padding: 0 0 14px 0;

    border: 0;

    font-family: Pixel, sans-serif;
    font-size: 24px;
    color: $color-elevation-one-primary-foreground;

    background-color: unset;
    background-image: url('../../assets/yellow_button.png');
    background-repeat: repeat-x;
    background-position: 0px 0px;

    .button-border {
        background-image: url('../../assets/yellow_button.png');
        background-repeat: no-repeat;
        height: 78px;
        position: absolute;

        &.mod-disabled {
            background-image: url('../../assets/yellow_button_disabled.png');
        }

        &.left {
            background-position: 0px -78px;
            top: 13px;
            left: -10px;
            width: 10px;
        }

        &.right {
            background-position: 0px -78px;
            top: 13px;
            right: -10px;
            width: 10px;
        }
    }

    &:hover {
        background-image: url('../../assets/yellow_button_hover.png');

        &.mod-disabled {
            background-image: url('../../assets/yellow_button_disabled.png');
        }

        .button-border {
            background-image: url('../../assets/yellow_button_hover.png');

            &.mod-disabled {
                background-image: url('../../assets/yellow_button_disabled.png');
            }
        }
    }

    &:active {
        padding: 0;
        background-image: url('../../assets/yellow_button_active.png');

        &.mod-disabled {
            padding-bottom: 14px;
            background-image: url('../../assets/yellow_button_disabled.png');
        }

        .button-border {
            background-image: url('../../assets/yellow_button_active.png');

            &.mod-disabled {
                background-image: url('../../assets/yellow_button_disabled.png');
            }
        }
    }

    &.mod-selected {
        padding: 0;
        background-image: url('../../assets/yellow_button_active.png');

        &.mod-disabled {
            padding-bottom: 14px;
            background-image: url('../../assets/yellow_button_disabled.png');
        }

        .button-border {
            background-image: url('../../assets/yellow_button_active.png');

            &.mod-disabled {
                background-image: url('../../assets/yellow_button_disabled.png');
            }
        }
    }

    &.mod-disabled {
        background-image: url('../../assets/yellow_button_disabled.png');
        color: $color-disabled-foreground;
    }
}
